<template>
  <div
    class="livestream pt-3"
    :style="{
      backgroundImage: `url(${require('@/assets/img/streams/' +
        current_stream.bg)})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }"
  >
    <div class="container-fluid">
      <div
        class="row align-items-center justify-content-between d-flex d-md-none d-lg-none"
      >
        <div class="col-4">
          <a href="/lobby">
            <img
              :src="require(`../assets/img/streams/${current_stream.back}`)"
              class="img-fluid"
              alt="back"
            />
          </a>
        </div>
        <div class="col-4" v-if="current_stream.view_more">
          <a href="javascript:void(0)">
            <img
              :src="
                require(`../assets/img/streams/${current_stream.view_more}`)
              "
              class="img-fluid"
              alt="view more btn"
              @click="openResourcesModal(current_stream)"
            />
          </a>
        </div>
      </div>

      <div
        class="branding d-flex flex-column mb-3 align-items-center justify-content-center"
      >
        <img
          src="@/assets/img/sateclogo.png"
          class="img-fluid logo"
          alt="logo"
        />

        <img
          :src="require(`../assets/img/streams/${current_stream.branding}`)"
          class="img-fluid words"
          alt="branding"
        />
      </div>

      <div class="row">
        <div class="col-sm-10 mx-auto">
          <div
            class="row no-gutters align-items-stretch mb-2 justify-content-center"
          >
            <div class="col-12 col-sm-12 col-md-8 col-lg-8">
              <div class="embed-responsive embed-responsive-16by9 h-100">
                <iframe
                  :src="current_stream.main_stream"
                  class="embed-responsive-item"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
              <div
                class="chat-wrapper h-100 d-flex flex-column justify-content-start align-items-start"
              >
                <div class="btns-area">
                  <button
                    class="btn btn-outline-primary btn-block rounded-0 mr-1 mr-lg-2"
                    @click="chatHidden = false"
                    :class="{ active: !chatHidden }"
                  >
                    Chat
                  </button>
                  <button
                    class="btn btn-outline-primary mt-0 btn-block rounded-0"
                    @click="chatHidden = true"
                    :class="{ active: chatHidden }"
                  >
                    Q & A
                  </button>
                </div>
                <iframe
                  :src="current_stream.chat"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  v-show="!chatHidden"
                ></iframe>
                <iframe
                  :src="current_stream.qna"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  v-show="chatHidden"
                ></iframe>
              </div>
            </div>
          </div>
          <div
            class="row align-items-center justify-content-between d-none d-md-flex d-lg-flex"
          >
            <div class="col-sm-2">
              <a href="/lobby">
                <img
                  :src="require(`../assets/img/streams/${current_stream.back}`)"
                  class="img-fluid"
                  alt="back"
                />
              </a>
            </div>
            <div class="col-sm-2" v-if="current_stream.view_more">
              <a href="javascript:void(0)">
                <img
                  :src="
                    require(`../assets/img/streams/${current_stream.view_more}`)
                  "
                  class="img-fluid"
                  alt="view more btn"
                  @click="openResourcesModal(current_stream)"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- resources modal -->
    <div
      class="modal fade"
      id="resourcesModal"
      tabindex="-1"
      aria-labelledby="resourcesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body p-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <h1 class="mb-5 popup-title">
              <span class="px-3 py-2">Resources</span>
            </h1>

            <div class="row justify-content-center">
              <div
                class="col-sm-6 mb-2"
                v-for="(resource, index) in current_stream.resources"
                :key="index"
              >
                <a
                  :href="resource.link"
                  @click="
                    logActivity(
                      `${$route.params.slug} pdf ${resource.link}`,
                      `download`
                    )
                  "
                  target="_blank"
                  v-if="resource.pdf"
                >
                  <img
                    :src="
                      require(`../assets/img/streams/${resource.thumbnail}`)
                    "
                    class="img-fluid img-thumbnail"
                    alt="thumbnail"
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  @click="openVideoModal(resource)"
                  v-if="!resource.pdf"
                >
                  <img
                    :src="
                      require(`../assets/img/streams/${resource.thumbnail}`)
                    "
                    class="img-fluid img-thumbnail"
                    alt="thumbnail"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- video modal -->
    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <video width="100%" height="100%" controls v-if="resource_video">
              <source :src="`${resource_video}`" type="video/mp4" />

              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
import Api from "@/assets/js/Api";
import util from "@/assets/js/util";

export default {
  name: "livestream",
  computed: {
    ...mapState(["livestreams", "base_url"]),
  },
  data() {
    return {
      chatHidden: false,
      current_stream: "",
      resource_video: null,
      attendance_interval: false,
    };
  },
  methods: {
    logActivity(event, type) {
      util.auth_middleware(this, event, type);
    },

    setAttendance() {
      if (this.attendance_interval) clearInterval(this.attendance_interval);

      let video = "";
      if (this.$route.params.slug == "maintenance_of_future") video = "mof";

      if (this.$route.params.slug == "green_technology") video = "gt";

      if (this.$route.params.slug == "4ir") video = "aerospace";

      if (this.$route.params.slug == "advanced_aerospace_technology")
        video = "art";

      if (this.$route.params.slug == "conference") video = "conference";

      let self = this;
      setTimeout(() => {
        Api.get(`aud_attendance/get_id?video=${video}`).then((res) => {
          let attendance_id = res.data.attendance_id;
          self.attendance_interval = setInterval(function () {
            if (!self.$route.params.slug) {
              if (self.attendance_interval)
                clearInterval(self.attendance_interval);
              return;
            }
            Api.get(
              `aud_attendance/update?attendance_id=${attendance_id}`
            ).catch((res) => {
              if (self.attendance_interval)
                clearInterval(self.attendance_interval);
            });
          }, 60000);
        });
      }, 5000);
    },
    openVideoModal(src) {
      this.resource_video = src.link;
      this.logActivity(`${this.$route.params.slug} ${src.link}`, `video click`);
      setTimeout(() => {
        $("#videoModal").modal("show");
      }, 100);
    },
    openResourcesModal(resources) {
      this.current_stream = resources;
      $("#resourcesModal").modal("show");
    },
    joinChannel() {
      let self = this;
      if (this.$route.params.slug == "maintenance_of_future")
        Echo.join(`auditorium-mof`);

      if (this.$route.params.slug == "green_technology")
        Echo.join(`auditorium-gt`);

      if (this.$route.params.slug == "4ir") Echo.join(`auditorium-aerospace`);

      if (this.$route.params.slug == "advanced_aerospace_technology")
        Echo.join(`auditorium-art`);

      if (this.$route.params.slug == "conference") Echo.join(`auditorium`);

      setTimeout(() => {
        self.setAttendance();
      }, 1000);
    },
    leaveChannel() {
      if (this.attendance_interval) clearInterval(this.attendance_interval);

      if (this.$route.params.slug == "maintenance_of_future")
        Echo.leave(`auditorium-mof`);

      if (this.$route.params.slug == "green_technology")
        Echo.leave(`auditorium-gt`);

      if (this.$route.params.slug == "4ir") Echo.leave(`auditorium-aerospace`);

      if (this.$route.params.slug == "advance_aerospace")
        Echo.leave(`auditorium-art`);

      if (this.$route.params.slug == "conference") Echo.leave(`auditorium`);
    },
  },
  created() {
    this.current_stream = this.livestreams[this.$route.params.slug];
  },
  mounted() {
    let self = this;
    $("#videoModal").on("hidden.bs.modal", function () {
      self.resource_video = "";
    });
    this.joinChannel();
    document.addEventListener(
      "visibilitychange",
      function () {
        if (document.hidden) self.leaveChannel();
        else self.joinChannel();
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>
.livestream {
  min-height: 100vh;
  .branding {
    position: relative;
    .logo {
      position: absolute;
      left: 2%;
      width: 200px;
    }
    .words {
      width: 40%;
    }
  }
  .chat-wrapper {
    .btns-area {
      z-index: 10;
      width: 100%;
      height: 40px;
      background: #f6f6f6;
      display: flex;
      justify-content: center;
      & button {
        text-transform: uppercase;
      }
    }
  }
  #resourcesModal {
    .modal-body {
      background: url("../assets/img/speaker/desktop/bg.png") no-repeat;
      background-position: center;
      background-size: cover;
      text-align: center;

      & img {
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .livestream {
    background: url("../assets/img/streams/4ir/mbg.jpg") no-repeat;
    .branding {
      .logo {
        position: absolute;
        left: 2%;
        width: 80px;
      }
      .words {
        width: 50%;
      }
    }
    .chat-wrapper {
      height: 450px !important;
    }
  }
}
</style>
